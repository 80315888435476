import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { showOSSelector, useArchOptions, useOsOptions } from '@grafana-cloud/collector';
import { OSSelectionWrapper } from 'components/OSSelectionWrapper';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';

const getStyles = (theme: GrafanaTheme2) => ({
  p: css`
    margin-top: 10px;
  `,
  topMargin: css`
    margin-top: ${theme.spacing(3)};
  `,
});

export const PlatformSelectionSection = () => {
  const styles = useStyles2(getStyles);

  const integrationId = useSelectedIntegrationId();

  const { data: osOptions } = useOsOptions(integrationId);
  const archOptions = useArchOptions(integrationId);

  const shouldShowOsSelector = osOptions && showOSSelector(osOptions, archOptions);

  if (!shouldShowOsSelector) {
    return null;
  }

  return (
    <li>
      <h2>Select platform</h2>
      <p className={styles.p}>Select a platform environment to generate a configuration component.</p>
      <div className={styles.topMargin}>
        <OSSelectionWrapper />
      </div>
    </li>
  );
};
