import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

import { isGrafanaAtLeast10 } from './misc';

enum connectionsFeatureToggle {
  homeSetupGuide = 'homeSetupGuide',
  dashboardRestore = 'dashboardRestore',
}

type ConnectionsFeatureToggles = FeatureToggles & {
  [key in connectionsFeatureToggle]: boolean;
};

function isFeatureToggleEnabled(featureName: keyof ConnectionsFeatureToggles): boolean {
  return Boolean((config.featureToggles as ConnectionsFeatureToggles)[featureName]);
}

export function isCSPAppEnabled(): boolean {
  return config.apps !== undefined && Object.hasOwn(config.apps, 'grafana-csp-app') && isGrafanaAtLeast10();
}

export function isCollectorAppEnabled(): boolean {
  return config.apps !== undefined && Boolean(config.apps['grafana-collector-app']);
}

export function isHomeSetupGuideEnabled(): boolean {
  return isFeatureToggleEnabled(connectionsFeatureToggle.homeSetupGuide);
}

export function isDashboardRestoreEnabled(): boolean {
  return isFeatureToggleEnabled(connectionsFeatureToggle.dashboardRestore);
}

/**
 * To mock the featureToggles in your tests, you can use jest.mock to override the @grafana/runtime module.
 * This allows you to set specific values for the featureToggles property, such as enabling or disabling certain features.
 *
 * jest.mock('@grafana/runtime', () => {
 *   const runtime = jest.requireActual('@grafana/runtime');
 *
 *   return {
 *     ...runtime,
 *     config: {
 *       ...runtime.config,
 *       buildInfo: {
 *         ...runtime.config.buildInfo,
 *         version: '11.0.0',
 *       },
 *       featureToggles: { dashboardRestore: true }, // Mocking the feature toggle
 *     },
 *     getPluginComponentExtensions: () => ({
 *       extensions: [],
 *     }),
 *   };
 * });
 */
