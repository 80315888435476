import React, { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { InstallMode, setInstallationModeValue, useCollectorDispatch } from '@grafana-cloud/collector';
import { useInstalledIntegrations, useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { useQueryParam } from 'hooks/useQueryParam';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RudderStackEvents } from 'utils/enums';

import { AgentConfigConvertPage } from './AgentConfigConvertPage';
import { AgentModeInfo } from './AgentModeInfo';
import { AgentModeSelectionPage } from './AgentModeSelectionPage';
import { Pages } from './Pages';
import { useAgentModeSelection } from './useAgentModeSelection';

export const isNewUserKey = 'grafana.connections.AgentModeSelectionNewUser';

export function AgentModeSelection(props: { children: React.ReactNode }): React.JSX.Element {
  const { agentModeSelection, setAgentModeSelection } = useAgentModeSelection();
  const [wasOnceNewUser, setWasOnceNewUser] = useLocalStorageState<boolean>(isNewUserKey);
  const { trackRudderStackEvent } = useRudderStack();
  const [pageQueryParam, setPageQueryParam] = useQueryParam('page', (page, fromURL) => {
    if (page) {
      if (fromURL) {
        trackRudderStackEvent(RudderStackEvents.AgentModePageSetFromUrl, { page });
      } else {
        trackRudderStackEvent(RudderStackEvents.AgentModePageUpdated, { page });
      }
    }
  });

  const collectorDispatch = useCollectorDispatch();
  const integrationId = useSelectedIntegrationId();

  useEffect(() => {
    const installationModeValue = pageQueryParam === Pages.Alloy ? InstallMode.ALLOY : InstallMode.STATIC;
    collectorDispatch(setInstallationModeValue({ installationModeValue }));
  }, [collectorDispatch, pageQueryParam, integrationId]);

  const selectedSource = useSelectedAgentOrSaasIntegration();

  const { installedIntegrations, isLoading, erroneousQuery } = useInstalledIntegrations();
  // users without an installed integration are considered new
  const isNewUser = !isLoading && erroneousQuery === undefined && installedIntegrations.length === 0;

  useEffect(() => {
    if (pageQueryParam === undefined && !isLoading) {
      if (agentModeSelection === undefined) {
        if (isNewUser) {
          setAgentModeSelection(Pages.Alloy);
          setWasOnceNewUser(true);
          setPageQueryParam(Pages.Alloy, true);
        } else {
          setPageQueryParam(Pages.Selection, true);
        }
      } else {
        setPageQueryParam(agentModeSelection, true);
      }
    }
  }, [
    agentModeSelection,
    isLoading,
    isNewUser,
    pageQueryParam,
    setAgentModeSelection,
    setPageQueryParam,
    setWasOnceNewUser,
  ]);

  return pageQueryParam === Pages.Selection ? (
    <AgentModeSelectionPage integrationName={selectedSource.name} />
  ) : pageQueryParam === Pages.Convert ? (
    <>
      <AgentModeInfo />
      <AgentConfigConvertPage integrationName={selectedSource.name} />
    </>
  ) : pageQueryParam === Pages.Static ? (
    <>
      <AgentModeInfo />
      {props.children}
    </>
  ) : pageQueryParam === Pages.Alloy ? (
    <>
      {!wasOnceNewUser && <AgentModeInfo />}
      {props.children}
    </>
  ) : (
    <></>
  );
}
