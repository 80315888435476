import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { OSSelection, isK8sAlloyInstructionsEnabled, useOsOptions, useArchOptions } from '@grafana-cloud/collector';
import { KubernetesBadge } from 'components/KubernetesBadge';
import { TextLink } from 'components/TextLink';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';

export const OSSelectionWrapper = ({ showStepBox = true }: { showStepBox?: boolean }) => {
  const styles = useStyles2(getStyles);
  const selectedIntegrationId = useSelectedIntegrationId();

  const { data: osOptions, isFetching } = useOsOptions(selectedIntegrationId);
  const archOptions = useArchOptions(selectedIntegrationId);

  if (!osOptions || osOptions.length === 0 || isFetching) {
    return null;
  }

  const content = (
    <>
      <OSSelection osOptions={osOptions} archOptions={archOptions} />
      {!isK8sAlloyInstructionsEnabled() && (
        <KubernetesBadge>
          <p>
            Looking to configure integrations for your Kubernetes environment? Check out{' '}
            <TextLink href="/a/grafana-k8s-app/configuration/integrations" inline>
              Kubernetes Monitoring
            </TextLink>{' '}
            with Grafana Cloud to enable this.
          </p>
        </KubernetesBadge>
      )}
    </>
  );

  return showStepBox ? <div className={styles.step}>{content}</div> : content;
};

const getStyles = (theme: GrafanaTheme2) => ({
  step: css({
    backgroundColor: theme.colors.background.secondary,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
});
