import { DashboardTrashResponse } from 'api/dashboards/data-model';
import { isErrorResponse } from 'models/api-models';
import { Dashboard } from 'types/dashboard';

import { apiGet, del, post } from '../baseApi';

export async function getFolderId(folderName: string): Promise<number> {
  const folders = await apiGet<Array<{ id: number; uid: string; title: string }>>('api/folders');
  const folder = folders.find((folder) => folder.title === folderName);
  if (!folder) {
    return -1;
  }
  return folder.id;
}

export function getFolderUid(folderName: string): string {
  return folderName.toLowerCase().replace(/ /g, '-');
}

export async function createFolder(folderName: string): Promise<number> {
  const folderId = await getFolderId(folderName);
  if (folderId < 0) {
    const folderUid = getFolderUid(folderName);
    const response = await post<{ id: number }>(
      '/api/folders',
      { title: folderName, uid: folderUid },
      { showSuccessAlert: false }
    );
    return response.id;
  }
  return folderId;
}

export async function removeFolder(folderName: string): Promise<void> {
  try {
    const folderUid = getFolderUid(folderName);
    await del(`/api/folders/${folderUid}`, { showSuccessAlert: false });
  } catch (error) {
    if (isErrorResponse(error) && error.status === 404) {
      return Promise.resolve();
    } else if (
      error instanceof SyntaxError &&
      (error.message === 'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data' ||
        error.message === 'Unexpected end of JSON input')
    ) {
      // workaround syntactically incorrect response from folders API.
      // in this case the folder deletion actually succeeds
      return Promise.resolve();
    } else {
      throw error;
    }
  }
}

export async function createDashboard(
  dashboardData: unknown,
  folderName: string,
  overwrite: boolean
): Promise<Dashboard> {
  const folderUid = getFolderUid(folderName);
  return post<Dashboard>(
    '/api/dashboards/db',
    {
      dashboard: dashboardData,
      folderUid,
      overwrite,
      message: 'creating dashboard from the Cloud Connections plugin',
    },
    { showSuccessAlert: false }
  );
}

export async function doesDashboardExist(uid: string): Promise<boolean> {
  try {
    await apiGet<unknown>(`api/dashboards/uid/${uid}`);
    return true;
  } catch (error) {
    if (isErrorResponse(error) && error.status === 404) {
      return false;
    } else {
      throw error;
    }
  }
}

export async function hardDeleteDashboard(dashboardUID: string): Promise<DashboardTrashResponse> {
  const response = await del<DashboardTrashResponse>(`/api/dashboards/uid/${dashboardUID}/trash`, {
    showSuccessAlert: false,
  });
  if (response?.data) {
    return response.data;
  }
  throw new Error('Error while force deleting the dashboard');
}
