import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { OsBasedOptions, setSelectedOs, useCollectorDispatch } from '@grafana-cloud/collector';
import { SelectGroup } from 'components/SelectGroup';
import { useQueryParam } from 'hooks/useQueryParam';
import { PrometheusMethodForwarding, PrometheusRemoteWrite } from 'utils/enums';

import { ViewStarterDashboard } from './ViewStarterDashboard';
import { K8S_INSTRUCTION, STANDARD_INSTRUCTION, instructionOptions, methodOptions, useCase } from './config';

const getStyles = (theme: GrafanaTheme2) => ({
  instructionWrapper: css`
    margin-top: 42px;
    margin-bottom: 32px;

    p {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
      color: ${theme.colors.text.maxContrast};
      margin-bottom: 10px;
    }
  `,
});

export const HostedPrometheusMetrics = () => {
  const styles = useStyles2(getStyles);
  const [remoteWrite, setRemoteWrite] = useQueryParam('remoteWrite');
  const [instruction, setInstruction] = useState<string | undefined>(STANDARD_INSTRUCTION);
  const method: PrometheusMethodForwarding = !remoteWrite
    ? PrometheusMethodForwarding.Alloy
    : PrometheusMethodForwarding.RemoteWrite;

  const collectorDispatch = useCollectorDispatch();

  const selectedOption = useCase.find(
    (useCase) => useCase.dependsOn.method === method && useCase.dependsOn.instruction === instruction
  );

  function onInstructionChange(instruction: string) {
    setInstruction(instruction);
    // we need to set the OS to K8s to make the K8s instructions visible
    if (instruction === K8S_INSTRUCTION) {
      collectorDispatch(setSelectedOs({ osValue: OsBasedOptions.K8s }));
    } else {
      collectorDispatch(setSelectedOs({ osValue: OsBasedOptions.Debian }));
    }
  }

  const SelectedComponent = selectedOption?.component;

  function onMethodChange(method: PrometheusMethodForwarding) {
    if (method === PrometheusMethodForwarding.RemoteWrite) {
      setRemoteWrite(PrometheusRemoteWrite.Direct, /*replace:*/ true);
    } else {
      setRemoteWrite(undefined, /*replace:*/ true);
    }
  }

  return (
    <ol>
      <li>
        <h2>Choose a method for forwarding metrics</h2>
        <p>
          There are several ways to send your Prometheus data to your Grafana Cloud Prometheus metrics service. Visit
          our documentation to read more about each methodology.
        </p>

        <SelectGroup options={methodOptions} value={method} onChange={onMethodChange} />

        {method && (
          <div className={styles.instructionWrapper}>
            <p>Show Instructions for</p>
            <RadioButtonGroup
              options={instructionOptions}
              value={instruction}
              onChange={(val) => onInstructionChange(val)}
            />
          </div>
        )}
      </li>
      {instruction && selectedOption && SelectedComponent && <SelectedComponent />}
      <ViewStarterDashboard />
    </ol>
  );
};
